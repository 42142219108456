import { useLocation as ReactUseLocation } from 'react-router-dom'

const useLocation = () => {
  const location = ReactUseLocation()

  const isLinkActive = (path) => location.pathname === path

  return {
    location,
    isLinkActive,
  }
}

export default useLocation
