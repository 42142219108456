import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Snackbar from '@material-ui/core/Snackbar'
import Typography from '@material-ui/core/Typography'

import { notificationTypes } from 'constants/propTypes'
import { NOTIFICATION_TYPE } from 'constants/enums'

import styles from './NotificationStyles'

const DefaultAnchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const Notification = ({
  classes,
  message,
  open,
  autoHideDuration,
  type,
  verticalAnchorOrigin,
  onClose,
}) => {
  const anchorOrigin = {
    ...DefaultAnchorOrigin,
    vertical: verticalAnchorOrigin,
  }

  return (
    <Snackbar
      className={classes[type]}
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      classes={{
        root: classes.root,
        anchorOriginTopLeft: classes.anchorOriginTopLeft,
        anchorOriginTopRight: classes.anchorOriginTopRight,
        anchorOriginBottomLeft: classes.anchorOriginBottomLeft,
        anchorOriginBottomRight: classes.anchorOriginBottomRight,
        anchorOriginTopCenter: classes.anchorOriginTopCenter,
        anchorOriginBottomCenter: classes.anchorOriginBottomCenter,
      }}
    >
      <Typography variant="subtitle1" className={classes.message}>
        {message}
      </Typography>
    </Snackbar>
  )
}

Notification.defaultProps = {
  type: NOTIFICATION_TYPE.info,
  verticalAnchorOrigin: 'top',
  autoHideDuration: 4000,
}

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string,
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  type: notificationTypes,
  verticalAnchorOrigin: PropTypes.string,
  onClose: PropTypes.func,
}

export default withStyles(styles)(Notification)
