import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core'

import styles from './SectionStyles'


const Section = ({ classes, className, children, noCollapse, ...props }) => {
  return (
    <Box
        component="section"
        {...props}
        className={classnames(noCollapse ? classes.noCollapse : classes.root, className)}
    >
      {children}
    </Box>
  )
}

Section.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  children: PropTypes.node,
  noCollapse: PropTypes.bool
}

Section.defaultProps = {
  noCollapse: false
}

export default withStyles(styles)(Section)
