export const NAV_PAGES = {
  contact: {
    label: 'Contact',
    path: '/contact',
  },
  about: {
    label: 'About Us',
    path: '/about',
  },
  careers: {
    label: 'Work With Us',
    path: '/work',
  },
  faq: {
    label: 'Contact',
    path: '/faq',
  },
  tos: {
    label: 'TOS',
    path: '/tos',
  },
  press: {
    label: 'Press Featuring Us',
    path: '/press',
  },
  blog: {
    label: 'Blog',
    path: 'https://fastaf.medium.com/',
  },
  referral: {
    label: 'Invite A Friend',
    path: '/referrals',
  },
  gift: {
    label: 'Gift Cards',
    path: '/gift',
  },
  // cryptoPaymentsRelease: {
  //   label: 'Crypto Payments Release',
  //   path: '/crypto-payments-release'
  // }
}
