import { useContext } from 'react'
import ReactDOM from 'react-dom'
import { useHistory } from 'react-router-dom'

import { useAlerts, useLoaders } from 'hooks'
import api from 'api'
import { AppContext } from 'context'


const useAuth = () => {
  const history = useHistory()
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError } = useAlerts()

  const {
    currentUser,
    setCurrentUser,
    setToken,
    token
  } = useContext(AppContext)

  const setCurrentUserAndToken = user => {
    setCurrentUser(user)
    if (user.token) {
      setToken(user.token)
      api.updateAuthToken(user.token)
      localStorage.setItem('authToken', user.token)
    }
  }

  const fetchMe = async () => {
    try {
      showLoading()
      const resp = await api.get('/api/v1/app/users/me')
      if (!resp.data) {
        throw new Error('/users/me didn\'t return data')
      }
      ReactDOM.unstable_batchedUpdates(() => {
        setCurrentUserAndToken(resp.data)
        hideLoading()
      })
      return resp.data
    } catch (e) {
      hideLoading()
      console.error(e)
    }
  }

  const updateMe = async user => {
    try {
      showLoading()
      const resp = await api.put(`/api/v1/app/users/${user.id}`, {
        user
      })
      ReactDOM.unstable_batchedUpdates(() => {
        setCurrentUserAndToken(resp.data)
        hideLoading()
      })
      return resp.data
    } catch (e) {
      hideLoading()
      console.error(e)
    }
  }

  const loginUser = async user => {
    try {
      showLoading()
      const resp = await api.post('/api/v1/login', {
        user
      })
      ReactDOM.unstable_batchedUpdates(() => {
        setCurrentUserAndToken(resp.data)
        hideLoading()
      })
      return resp.data
    } catch (e) {
      hideLoading()
      showAlertError('Email or password is incorrect')
      console.error(e)
    }
  }

  const signupUser = async user => {
    try {
      showLoading()
      const resp = await api.post('/api/v1/signup', {
        user
      })
      ReactDOM.unstable_batchedUpdates(() => {
        setCurrentUserAndToken(resp.data)
        hideLoading()
      })
      return resp.data
    } catch (e) {
      hideLoading()
      console.error(e)
      showAlertError('Unable to create the account. Email may already exist.')
    }
  }

  const resetPassword = async (password, changeToken) => {
    try {
      showLoading()
      const resp = await api.post('/api/v1/reset_password', {
        password,
        change_password_token: changeToken
      })
      hideLoading()
      return resp.data
    } catch (e) {
      hideLoading()
      console.error(e)
    }
  }

  const forgotPassword = async email => {
    try {
      showLoading()
      await api.post('/api/v1/forgot_password', {
        email
      })
      hideLoading()
    } catch (e) {
      hideLoading()
      console.error(e)
    }
  }

  const logoutUser = () => {
    setCurrentUser(null)
    setToken(null)
    localStorage.removeItem('authToken')
    history.push('/')
  }

  return {
    token,
    currentUser,
    fetchMe,
    updateMe,
    loginUser,
    signupUser,
    logoutUser,
    resetPassword,
    forgotPassword
  }
}

export default useAuth
