import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'

import { useMediaQuery } from 'hooks'
import { APP_STORE_LINK } from 'lib/config'

import Section from 'components/Section'
import ExternalLink from 'components/ExternalLink'
import GetAppLink from 'components/GetAppLink'
import AppleIcon from 'assets/Images/Apple-icon.png'

import styles from './GetAppCTAStyles'

const GetAppCTA = ({ classes, color }) => {
  const { isMobileTablet } = useMediaQuery()

  if (isMobileTablet) {
    return (
      <Box className={classes.mobileRoot}>
        <ExternalLink
          onClick={(e) => {
            e.preventDefault()
            window.open(APP_STORE_LINK, '_blank')
          }}
          href={APP_STORE_LINK}
          className={classes.appStoreLink}
          underline="none"
        >
          <img src={AppleIcon} alt="" className={classes.appleIcon} />
          <Typography variant="h6" className={classes.linkText}>
            Get it on the App Store
          </Typography>
        </ExternalLink>
      </Box>
    )
  }

  return (
    <Section
      className={classNames(classes.section, {
        [classes.secondaryBg]: color === 'secondary',
        [classes.ternaryBg]: color === 'ternary',
      })}
      noCollapse
    >
      <Container className={classes.container}>
        <Typography variant="h2" className={classes.ctaHeader}>
          Get it in the
          <br />
          App Store today.
        </Typography>
        <GetAppLink className={classes.downloadLink} color={classNames({
          'primary': (color === 'primary' || color === 'ternary'),
          'secondary': color === 'secondary',
        })} />
      </Container>
    </Section>
  )
}

GetAppCTA.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.oneOf(['primary', 'secondary', 'ternary']),
}

GetAppCTA.defaultProps = {
  color: 'primary',
}

export default withStyles(styles)(GetAppCTA)
