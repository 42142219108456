import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Notification from 'components/Notification'
import ContextSpinner from 'components/ContextSpinner'
import { AppContext } from 'context'
import { Routes } from 'config/Routes'

import styles from './AppStyles'

const App = ({ classes }) => {
  const { alert, closeAlert } = useContext(AppContext)

  return (
    <Router routes={Routes}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classes.wrapper}
      >
        <Notification
          open={alert?.open}
          message={alert?.message}
          type={alert?.type}
          onClose={closeAlert}
        />
        <Grid item className={classes.main}>
          {Routes.map(({ path, component }, key) => (
            <Route exact path={path} component={component} key={key} />
          ))}
        </Grid>
      </Grid>
      <ContextSpinner />
    </Router>
  )
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(App)
