const jssStyles = (theme) => ({
  root: {
    maxWidth: theme.layout.desktopContentWrapWidth,
    position: 'relative',
    '&$wide': {
      maxWidth: theme.layout.desktopWrapWidth,
    },
  },
  wide: {},
})

export default jssStyles