import api from 'api'

const useData = () => {
  const submitCTAForm = async ({
    email,
    name
  }) => {
    try {
      await api.post('/api/v1/emails/signup', {
        email,
        name
      })
    } catch (e) {
      console.error(e)
    }
  }

  const submitPartnerForm = async ({
    name,
    email,
    company,
    companyWebsite,
    title,
    message
  }) => {
    try {
      await api.post('/api/v1/partnerships', {
        name,
        email,
        company,
        companyWebsite,
        title,
        message
      })
    } catch (e) {
      console.error(e)
    }
  }

  const submitGiftCardPurchase = async ({
    payment_token,
    recipient_name,
    recipient_phone,
    recipient_email,
    sender_name,
    sender_email,
    message,
    amount,
    date,
    style,
    billing_fullname,
    billing_address,
    billing_city,
    billing_state,
    billing_zip,
    billing_email
  }) => {
    try {
      return api.post('/api/v1/gift_certificates', {
        payment_token,
        gift_certificate: {
          style,
          recipient_name,
          recipient_phone,
          recipient_email,
          sender_name,
          sender_email,
          message,
          amount,
          date
        },
        billing: {
          name: billing_fullname,
          email: billing_email,
          address: {
            line1: billing_address,
            city: billing_city,
            state: billing_state,
            country: 'US',
            postal_code: billing_zip
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const getGiftCardDetails = async ({
    id
  }) => {
    return new Promise((resolve, reject) => {
      api.get(`/api/v1/gift_certificates/${id}`)
        .then((result) => resolve(result))
        .catch((e) => {
          console.error(e)
          if (e.data) {
            reject({
              ...e,
              message: e.data.message,
            })
          }
          else {
            reject(e)
          }
        })
    })
  }

  const getFavoritesList = async ({
    collectionId
  }) => {
    return new Promise((resolve, reject) => {
      api.get(`/api/v1/favorites_lists/${collectionId}`)
        .then((result) => resolve(result))
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  }

  const getGiftOrderDetails = async ({ giftOrderId }) => {
    try {
      const res = await api.get(`/api/v2/orders/${giftOrderId}/gift`)
      return res;
    } catch (e) {
      console.error(e)
      return e;
    }
  }

  return {
    submitCTAForm,
    submitPartnerForm,
    submitGiftCardPurchase,
    getGiftCardDetails,
    getFavoritesList,
    getGiftOrderDetails
  }
}

export default useData