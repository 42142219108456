const jssStyles = (theme) => ({
  appbar: {
    zIndex: 5,
    backgroundColor: theme.palette.common.white,
    padding: '0 2.4rem',
    '&$transparent': {
      backgroundColor: 'transparent',
    },
  },
  transparent: {},
  transparentBg: {},
  toolbar: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: theme.layout.desktopWrapWidth,
    width: '100%',
    margin: '0 auto',
    height: theme.layout.headerHeight.mobile,
    [theme.breakpoints.up('desktop')]: {
      height: theme.layout.headerHeight.desktop,
    },
  },
  headerNav: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingRight: '1.2rem',
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: 0,
    },
  },
  link: {
    fontWeight: 'bold',
    fontSize: '2.4rem',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    margin: '1.2rem 0',
    '&$transparentBg': {
      color: theme.palette.grays.med,
    },
    [theme.breakpoints.up('desktop')]: {
      fontWeight: 600,
      fontSize: 'inherit',
      margin: '0 2rem',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  disabledLink: {
    opacity: 0.6,

    '&:hover': {
      cursor: 'default',
      textDecoration: 'none',
    },
  },
  drawer: {
    padding: '2.4rem 3.2rem 3.2rem',
    width: theme.breakpoints.values['tablet'] + 'px',
    maxWidth: '100%',
  },
  drawerToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    marginBottom: '4.6rem',
    minHeight: 0,
  },
  closeButton: {
    padding: 0,
  },
})

export default jssStyles