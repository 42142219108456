import React, { useContext } from 'react'
import { AppContext } from 'context'
import Spinner from 'components/Spinner'

const ContextSpinner = ({ ...props }) => {
  const { isLoading } = useContext(AppContext)
  return <Spinner isLoading={isLoading} />
}

export default ContextSpinner
