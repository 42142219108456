const jssStyles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: 56,
    width: '100%'
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  success: {
    backgroundColor: theme.palette.warnings.success
  },
  error: {
    backgroundColor: theme.palette.warnings.danger
  },
  warning: {
    backgroundColor: theme.palette.warnings.error
  },
  general: {
    backgroundColor: theme.palette.common.black
  },
  anchorOriginTopLeft: {
    top: 0,
    left: 0
  },
  anchorOriginTopRight: {
    top: 0,
    right: 0
  },
  anchorOriginBottomLeft: {
    left: 0,
    bottom: 0
  },
  anchorOriginBottomRight: {
    right: 0,
    bottom: 0
  },
  anchorOriginTopCenter: {
    top: 0
  },
  anchorOriginBottomCenter: {
    bottom: 0
  },
  message: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 400
  }
})

export default jssStyles