import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/core/styles'

import FavoritesTheme from 'theme/favorites'
import Header from 'components/Header'
import Footer from 'components/Footer'
import GetAppCTA from 'components/GetAppCTA'

const FavoritesLayout = ({
  children,
  hasAppCTA,
  hasFooter,
  headerProps,
  appCTAProps,
}) => (
  <ThemeProvider theme={FavoritesTheme}>
    <Header {...headerProps} />
    {children}
    {hasAppCTA && (<GetAppCTA {...appCTAProps} />)}
    {hasFooter && (<Footer />)}
  </ThemeProvider>
)

FavoritesLayout.propTypes = {
  children: PropTypes.node,
  hasAppCTA: PropTypes.bool,
  hasFooter: PropTypes.bool,
  headerProps: PropTypes.object,
  appCTAProps: PropTypes.object,
}

FavoritesLayout.defaultProps = {
  hasAppCTA: true,
  hasFooter: true,
  headerProps: {},
  appCTAProps: {}
}

export default FavoritesLayout
