import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import MuiLink from '@material-ui/core/Link'

const Link = ({ children, to, ...otherProps }) => (
  <MuiLink component={RouterLink} to={to} {...otherProps}>{children}</MuiLink>
)

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string
}

export default Link
