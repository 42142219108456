import React from 'react'
import { Suspense } from 'react'

import { NAV_PAGES } from 'constants/nav'

import {
  DefaultLayout,
  HomeLayout,
  FavoritesLayout,
} from 'layouts'

const LeeSeo = React.lazy(() => import('components/LeeSeo/LeeSeo'))
const Home = React.lazy(() => import('containers/Home'))
const Contact = React.lazy(() => import('containers/Contact'))
const FAQ = React.lazy(() => import('containers/FAQ'))
const TOS = React.lazy(() => import('containers/TOS'))
const About = React.lazy(() => import('containers/About'))
const Press = React.lazy(() => import('containers/Press'))
const Work = React.lazy(() => import('containers/Work'))
const Referral = React.lazy(() => import('containers/Referral'))
const GiftCardLanding = React.lazy(() => import('containers/GiftCardLanding'))
const GiftCardSuccess = React.lazy(() => import('containers/GiftCardSuccess'))
const GiftCardRedeem = React.lazy(() => import('containers/GiftCardRedeem'))
const GiftOrder = React.lazy(() => import('containers/GiftOrder'))
const Favorites = React.lazy(() => import('containers/Favorites'))
const ForgotPassword = React.lazy(() =>
  import('containers/auth/ForgotPassword')
)
const ResetPassword = React.lazy(() => import('containers/auth/ResetPassword'))

export const Routes = [
  {
    path: '/',
    component: () => (
      <Suspense fallback={'Loading...'}>
        <HomeLayout>
          <Home />
        </HomeLayout>
      </Suspense>
    ),
  },
  // {
  //   path: NAV_PAGES.cryptoPaymentsRelease.path,
  //   component: () => <CryptoPaymentsRelease />,
  // },
  {
    path: NAV_PAGES.contact.path,
    component: () => (
      <Suspense fallback={'Lodaing...'}>
        <Contact />
      </Suspense>
    ),
  },
  // {
  //   path: '/lee-hnetinka',
  //   component: () => <LeeSeo />,
  // },
  {
    path: NAV_PAGES.faq.path,
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout>
          <FAQ />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: NAV_PAGES.tos.path,
    component: () => (
      <Suspense fallback={'Lodaing...'}>
        <DefaultLayout>
          <TOS />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: NAV_PAGES.about.path,
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout>
          <About />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: NAV_PAGES.press.path,
    component: () => (
      <Suspense fallback={'Lodaing...'}>
        <DefaultLayout>
          <Press />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: NAV_PAGES.careers.path,
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout>
          <Work />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: NAV_PAGES.referral.path,
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout
          hasContactSection={false}
          appCTAProps={{ color: 'ternary' }}
        >
          <Referral />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: NAV_PAGES.gift.path,
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout
          hasContactSection={false}
          appCTAProps={{ color: 'ternary' }}
        >
          <GiftCardLanding />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: NAV_PAGES.gift.path + '-success',
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout
          hasAppCTA={false}
          hasContactSection={false}
          hasFooter={false}
        >
          <GiftCardSuccess />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: NAV_PAGES.gift.path + '/:redeemId',
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout
          hasContactSection={false}
          hasAppCTA={false}
          hasFooter={false}
          headerProps={{ transparent: true, linkColor: 'textPrimary' }}
        >
          <GiftCardRedeem />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: '/gift_order/:giftOrderId',
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout
          headerProps={{ hideNav: true }}
          hasFooter={false}
          hasAppCTA={false}
          hasContactSection={false}
        >
          <GiftOrder />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: '/favorites/:collectionId',
    component: () => (
      <Suspense fallback={'Loading...'}>
        <FavoritesLayout>
          <Favorites />
        </FavoritesLayout>
      </Suspense>
    ),
  },
  {
    path: '/forgot_password',
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout>
          <ForgotPassword />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: '/reset_password/:token',
    component: () => (
      <Suspense fallback={'Loading...'}>
        <DefaultLayout>
          <ResetPassword />
        </DefaultLayout>
      </Suspense>
    ),
  },
  {
    path: '/lee-hnetinka',
    component: () => (
      <Suspense fallback={'Loading...'}>
        <LeeSeo />
      </Suspense>
    ),
  },
]
