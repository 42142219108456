import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import Section from 'components/Section'
import Button from 'components/Button'
import ContentWrap from 'components/ContentWrap'
import ExternalLink from 'components/ExternalLink'
import { DARKSTORE_EMAIL } from 'lib/config'

import styles from './ContactSectionStyles'

const ContactSection = ({ classes }) => {
  const handleClick = () => window.open(`mailto:${DARKSTORE_EMAIL}`)

  return (
    <Section className={classes.section} noCollapse>
      <ContentWrap className={classes.wrap}>
        <Typography variant="h2" align="center">
          Let’s Talk
        </Typography>
        <Typography
          className={classes.body}
          variant="body1"
          align="center"
          color="textSecondary"
        >
          Tell us how we can help make your products widely available.
        </Typography>
        <Button
          component={ExternalLink}
          href={`mailto:${DARKSTORE_EMAIL}`}
          onClick={handleClick}
          className={classes.button}
          variant="contained"
          color="primary"
          disableElevation
        >
          Get in Touch
        </Button>
      </ContentWrap>
    </Section>
  )
}

ContactSection.defaultProps = {}

ContactSection.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactSection)
