import {
  createMuiTheme
} from '@material-ui/core'

export const alertBGColor = '#305BF2'

const muiTheme = createMuiTheme({
  layout: {
    desktopWrapWidth: 1280,
    desktopContentWrapWidth: 1060,
    headerScrollThreshold: 40,
    headerHeight: {
      mobile: '6.4rem',
      desktop: '8.0rem',
    },
    marqueeHeight: {
      mobile: '4rem',
      desktop: '4rem',
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 461,
      desktop: 960,
    },
  },
  palette: {
    common: {
      black: '#111111',
      blue: '#264FE1',
    },
    primary: {
      contrastText: '#ffffff',
      main: '#305BF2',
      dark: '#02149b',
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#111111',
    },
    grays: {
      darkest: '#505050',
      dark: '#858585',
      med: '#CCCCCC',
      light: '#F7F7F7',
    },
    warnings: {
      success: '#4CAF50',
      caution: '#FD6B6B',
      error: '#FF9800',
      danger: '#E52B20',
    },
    text: {
      primary: '#111111',
      secondary: '#505050',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    htmlFontSize: 10,
  },
  shadows: ['none'],
  spacing: 20,
})

export const theme = muiTheme

theme.overrides = {
  MuiButton: {
    root: {
      height: '4.8rem',
      paddingLeft: '3.2rem',
      paddingRight: '3.2rem',
      [theme.breakpoints.up('desktop')]: {
        height: '6.0rem',
        paddingLeft: '4.8rem',
        paddingRight: '4.8rem',
      },
    },
  },
  MuiCardContent: {
    root: {
      padding: '1.6rem 0.8rem',
      '&:last-child': {
        paddingBottom: '1.6rem',
      },
      [theme.breakpoints.up('desktop')]: {
        padding: '2.0rem 2.4rem 3.2rem',
        '&:last-child': {
          paddingBottom: '3.2rem',
        },
      },
    },
  },
  MuiContainer: {
    root: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  MuiInputLabel: {
    outlined: {
      lineHeight: 1,
      textAlign: 'center',
      transform: 'translate(0, 1.9rem) scale(1)',
      width: '100%',
      '&$shrink': {
        width: 'auto',
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '121%',
    },
    contained: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  MuiPaper: {
    outlined: {
      borderColor: theme.palette.grays.light,
    },
  },
  MuiToolbar: {
    gutters: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  MuiLinearProgress: {
    barColorSecondary: {
      backgroundColor: theme.palette.common.black,
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: 'none',
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: '129%',
      [theme.breakpoints.up('desktop')]: {
        fontSize: '1.6rem',
      },
    },
    head: {
      color: theme.palette.grays.dark,
      fontWeight: 400,
      verticalAlign: 'top',
    },
  },
  MuiRadio: {
    root: {
      color: theme.palette.common.black
    },
  },
}

theme.typography.h1 = {
  fontFamily: 'Inter Display',
  fontSize: '4.8rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '95%',
  textAlign: 'center',
  [theme.breakpoints.up('tablet')]: {
    fontSize: '6.0rem',
    textAlign: 'inherit',
  },
  [theme.breakpoints.up('desktop')]: {
    fontSize: '10.0rem',
    textAlign: 'inherit',
  },
}

theme.typography.h2 = {
  fontFamily: 'Inter Display',
  fontSize: '3.2rem',
  fontWeight: 500,
  lineHeight: '110%',
  textAlign: 'center',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '6.0rem',
    textAlign: 'inherit',
  },
}

theme.typography.h3 = {
  fontSize: '1.6rem',
  fontWeight: 400,
  lineHeight: '140%',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '2.4rem',
  },
}

// Used for disclaimer text on Gift Card landing page
theme.typography.h4 = {
  fontSize: '1.2rem',
  fontWeight: 500,
  lineHeight: '2.0rem',
}

// Used for header text in Gift Card steps
theme.typography.h5 = {
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '143%',
  textAlign: 'center',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '3.2rem',
  },
}

// Used for nav links
theme.typography.h6 = {
  fontFamily: 'Inter Display',
  fontSize: '1.4rem',
  fontWeight: 600,
  lineHeight: '130%',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '1.5rem',
  },
}

// Used in Notifications and TextField
theme.typography.subtitle1 = {
  fontSize: '1.6rem',
  fontWeight: 600,
  lineHeight: '1.9rem',
  textAlign: 'center',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '2.0rem',
    lineHeight: '2.4rem',
    textAlign: 'center',
  },
}

// Used for copyright text in footer
theme.typography.subtitle2 = {
  fontSize: '1.2rem',
  fontWeight: 'normal',
  lineHeight: '110%',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '1.4rem',
  },
}

theme.typography.body1 = {
  fontSize: '1.5rem',
  fontWeight: 'normal',
  lineHeight: '170%',
}

// Used in Work With Us values list, and Gift Card Landing hero blurb
theme.typography.body2 = {
  fontSize: '1.6rem',
  fontWeight: 400,
  lineHeight: '160%',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '2.4rem',
  },
}

theme.typography.button = {
  fontFamily: 'Inter Display',
  fontSize: '1.4rem',
  fontWeight: 600,
  lineHeight: '130%',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '1.5rem',
  },
}

// Used for custom input labels
theme.typography.overline = {
  fontSize: '1.4rem',
  fontWeight: 600,
  lineHeight: '140%',
}

// Used for minor header on Gift Card page
theme.typography.caption = {
  fontSize: '1.4rem',
  fontWeight: 700,
  lineHeight: '2.0rem',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '1.4rem',
    lineHeight: '2.0rem',
  },
}
