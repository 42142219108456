import { useContext } from 'react'

import { AppContext } from 'context'
import { NOTIFICATION_TYPE } from 'constants/enums'

const useAlerts = () => {
  const { setAlert } = useContext(AppContext)

  const showAlertError = message =>
    setAlert({ message, open: true, type: NOTIFICATION_TYPE.error })
  const showAlertWarning = message =>
    setAlert({ message, open: true, type: NOTIFICATION_TYPE.warning })
  const showAlertSuccess = message =>
    setAlert({ message, open: true, type: NOTIFICATION_TYPE.success })
  const showAlertInfo = message =>
    setAlert({ message, open: true, type: NOTIFICATION_TYPE.info })
  const showAlertGeneral = message =>
    setAlert({ message, open: true, type: NOTIFICATION_TYPE.general })

  return {
    showAlertError,
    showAlertWarning,
    showAlertSuccess,
    showAlertInfo,
    showAlertGeneral
  }
}

export default useAlerts
