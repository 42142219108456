import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, useTheme } from '@material-ui/core'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { useMediaQuery, useLocation } from 'hooks'
import { NAV_PAGES } from 'constants/nav'

import Link from 'components/Link'
import ExternalLink from 'components/ExternalLink'
import FastAFLogo from 'components/FastAFLogo'
import HamburgerMenuButton from 'components/HamburgerMenuButton'

import styles from './HeaderStyles'

const Header = ({ classes, transparent, linkColor, hideNav = false }) => {
  const theme = useTheme()
  const { isLinkActive } = useLocation()
  const { isDesktop } = useMediaQuery()
  const { headerScrollThreshold } = theme.layout

  const [showMenu, setShowMenu] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false)

  const handleOpenMenu = () => setShowMenu(true)
  const handleCloseMenu = () => setShowMenu(false)

  const handleLinkClick = () => {
    window.scrollTo(0, 0)
    handleCloseMenu()
  }

  const handleScroll = (e) => {
    if (window.scrollY > headerScrollThreshold && !hasScrolled) {
      setHasScrolled(true)
    }
    else if (window.scrollY < headerScrollThreshold && hasScrolled) {
      setHasScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const appbarClasses = classNames({
    [classes.appbar]: true,
    [classes.transparent]: transparent && !hasScrolled,
  })

  const linkClasses = {
    [classes.link]: true,
    [classes.transparentBg]: (!linkColor) && transparent && isDesktop,
  }

  const linkProps = {
    color: linkColor || 'textPrimary',
    underline: 'hover',
    variant: 'h6',
  }

  const Nav = () => (
    <nav className={classes.headerNav}>
      <Link
        to={NAV_PAGES.about.path}
        {...linkProps}
        className={classNames({
          ...linkClasses,
          [classes.activeLink]: isLinkActive(NAV_PAGES.about.path),
        })}
        onClick={handleLinkClick}
      >
        {NAV_PAGES.about.label}
      </Link>
      {/* <Link
        to={NAV_PAGES.careers.path}
        {...linkProps}
        className={classNames({
          ...linkClasses,
          [classes.activeLink]: isLinkActive(NAV_PAGES.careers.path),
        })}
        onClick={handleLinkClick}
      >
        {NAV_PAGES.careers.label}
      </Link> */}
      {/* <Link
        to={NAV_PAGES.gift.path}
        {...linkProps}
        className={classNames({
          ...linkClasses,
          [classes.activeLink]: isLinkActive(NAV_PAGES.gift.path),
        })}
        onClick={handleLinkClick}
      >
        {NAV_PAGES.gift.label}
      </Link> */}
      <Link
        to={NAV_PAGES.faq.path}
        {...linkProps}
        className={classNames({
          ...linkClasses,
          [classes.activeLink]: isLinkActive(NAV_PAGES.faq.path),
        })}
        onClick={handleLinkClick}
      >
        {NAV_PAGES.faq.label}
      </Link>
      {/*
      <Link
        to={NAV_PAGES.press.path}
        className={classNames({
          ...linkClasses,
          [classes.activeLink]: isLinkActive(NAV_PAGES.press.path),
        })}
        onClick={handleLinkClick}
      >
        {NAV_PAGES.press.label}
      </Link>
      */}
      {/* <ExternalLink
        {...linkProps}
        href={NAV_PAGES.blog.path}
        target="_blank"
        className={classNames(linkClasses)}
      >
        {NAV_PAGES.blog.label}
      </ExternalLink> */}
    </nav>
  )

  return (
    <AppBar position="fixed" elevation={0} className={appbarClasses}>
      <Toolbar className={classes.toolbar} disableGutters>
        <Link to="/" onClick={handleLinkClick}>
          <FastAFLogo width={88} fill={(transparent && !hasScrolled) ? 'white' : 'black'} />
        </Link>
        {!hideNav && isDesktop && <Nav />}
        {!hideNav && !isDesktop && (
          <>
            <HamburgerMenuButton
              menuShown={showMenu}
              onClick={handleOpenMenu}
              color={
                transparent
                  ? theme.palette.primary.contrastText
                  : theme.palette.text.primary
              }
            />
            <Drawer
              anchor="right"
              open={showMenu}
              onClose={handleOpenMenu}
              classes={{ paper: classes.drawer }}
            >
              <Toolbar className={classes.drawerToolbar}>
                <FastAFLogo width={88} fill="black" />
                <IconButton
                  onClick={handleCloseMenu}
                  className={classes.closeButton}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
              <Nav />
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  transparent: PropTypes.bool,
  linkColor: PropTypes.oneOf([
    'initial',
    'inherit',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary',
    'error',
  ])
}

Header.defaultProps = {
  transparent: false,
}

export default withStyles(styles)(Header)
