import { useState, useEffect } from 'react'

export default function useLoaded({ src, srcSet }) {
  const [isLoaded, setIsLoaded] = useState(false)
  console.log('check', src)
  useEffect(() => {
    if (!src && !srcSet) {
      return undefined
    }

    setIsLoaded(false)

    let active = true
    const image = new Image()
    image.src = src
    image.srcSet = srcSet
    image.onload = () => {
      if (!active) {
        return
      }
      setIsLoaded(true)
    }
    image.onerror = () => {
      if (!active) {
        return
      }
      setIsLoaded(false)
    }

    return () => {
      active = false
    }
  }, [src, srcSet])

  return isLoaded
}
