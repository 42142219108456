import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core'
import Container from '@material-ui/core/Container'

import styles from './ContentWrapStyles'


const ContentWrap = ({ children, classes, className, width, ...otherProps }) => (
  <Container
      className={classnames(classes.root, className, { [classes.wide]: (width === 'wide') })}
      {...otherProps}
  >{children}</Container>
)

ContentWrap.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  width: PropTypes.oneOf(['narrow', 'wide']),
}

ContentWrap.defaultProps = {
  width: 'narrow',
}

export default withStyles(styles)(ContentWrap)
