const jssStyles = (theme) => ({
  section: {
    background: theme.palette.common.white,
    borderTop: ['0.2rem', 'solid', theme.palette.grays.light].join(' '),
  },
  wrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  body: {
    marginTop: '2.4rem',
    marginBottom: '2.4rem',
  },
  button: {
    textTransform: 'none',
    width: '25.4rem',
  },
})

export default jssStyles
