import {
  createMuiTheme
} from '@material-ui/core'

import { theme as baseTheme } from './index'

const theme = createMuiTheme(baseTheme);

/*
 * Theme overrides specific to the Favorites Webview
 */

theme.overrides.MuiButton = {
  root: {
    height: '3.0rem',
  },
}

theme.typography.h2 = {
  fontFamily: 'Inter Display',
  fontSize: '2.6rem',
  fontWeight: 600,
  lineHeight: '3.2rem',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '6.0rem',
    lineHeight: '6.6rem',
  },
}

theme.typography.subtitle1 = {
  fontSize: '1.0rem',
  fontWeight: 500,
  lineHeight: '121%',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '1.4rem',
  },
}

theme.typography.body1 = {
  color: '#222222',
  fontSize: '1.4rem',
  fontWeight: 'normal',
  lineHeight: '170%',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '1.6rem',
  },
}

theme.typography.body2 = {
  color: '#222222',
  fontSize: '1.4rem',
  fontWeight: 500,
  lineHeight: '160%',
}

theme.typography.button = {
  fontSize: '1.2rem',
  fontWeight: 600,
  textTransform: 'none',
  [theme.breakpoints.up('desktop')]: {
    fontSize: '1.6rem',
    paddingLeft: '3.6rem',
    paddingRight: '3.6rem',
  },
}

export default theme
