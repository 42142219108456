import React, {
  createContext,
  useState,
} from 'react'

export const AppContext = createContext()

export const AppProvider = ({
  children
}) => {
  const [token, setToken] = useState()
  const [alert, setAlert] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const closeAlert = () => setAlert()

  const value = {
    token,
    setToken,
    alert,
    setAlert,
    closeAlert,
    isLoading,
    setIsLoading,
  }

  return ( <
    AppContext.Provider value = {
      value
    } > {
      children
    } < /AppContext.Provider>
  )
}