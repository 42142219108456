import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'

import { useLocation } from 'hooks'
import { NAV_PAGES } from 'constants/nav'

import Link from 'components/Link'
import ExternalLink from 'components/ExternalLink'

import styles from './FooterStyles'

const Footer = ({ classes, color }) => {
  const { isLinkActive } = useLocation()
  const currentYear = new Date().getFullYear()

  const handleLinkClick = () => {
    window.scrollTo(0, 0)
  }

  const linkProps = {
    underline: 'hover',
    variant: 'h6',
    onClick: handleLinkClick,
  }

  return (
    <>
      <Box
        component="footer"
        className={classNames(classes.footer, {
          [classes.secondaryBg]: color === 'secondary',
        })}
      >
        <Box className={classes.innerWrap}>
          <Box className={classes.linkContainer}>
            <Link
              to={NAV_PAGES.about.path}
              {...linkProps}
              className={classNames({
                [classes.link]: true,
                [classes.activeLink]: isLinkActive(NAV_PAGES.about.path),
              })}
            >
              {NAV_PAGES.about.label}
            </Link>
            <Link
              to={NAV_PAGES.careers.path}
              {...linkProps}
              className={classNames({
                [classes.link]: true,
                [classes.activeLink]: isLinkActive(NAV_PAGES.careers.path),
              })}
            >
              {NAV_PAGES.careers.label}
            </Link>
            <ExternalLink
              href="https://darkstoregh.github.io/legal/fastaf_tos"
              className={classes.link}
              variant="h6"
            >
              Terms Of Service
            </ExternalLink>
            {/* <Link
              to={NAV_PAGES.referral.path}
              {...linkProps}
              className={classNames({
                  [classes.link]: true,
                  [classes.activeLink]: isLinkActive(NAV_PAGES.referral.path),
              })}
            >
              {NAV_PAGES.referral.label}
            </Link> */}
          </Box>
          <Box className={classes.socialContainer}>
            <ExternalLink
              href="https://twitter.com/fastaf?lang=en"
              className={classes.socialLink}
            >
              <TwitterIcon className={classes.socialIcon} />
            </ExternalLink>
            {/* <ExternalLink
              href="https://instagram.com/fastaf"
              className={classes.socialLink}
            >
              <InstagramIcon className={classes.socialIcon} />
            </ExternalLink> */}
          </Box>
          <Box className={classes.copyrightContainer}>
            <Typography variant="subtitle2">
              <span className={classes.nowrap}>
                Copyright &copy; {currentYear} FastAF Technologies, Inc.
              </span>{' '}
              <span className={classes.nowrap}>All rights reserved.</span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary']),
}

Footer.defaultProps = {
  color: 'primary',
}

export default withStyles(styles)(Footer)
