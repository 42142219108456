const jssStyles = (theme) => ({
  wrapper: {
    minHeight: '100vh'
  },
  main: {
    backgroundColor: theme.palette.grays.light,
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto'
  }
})

export default jssStyles
