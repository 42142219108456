import React from 'react'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Footer from 'components/Footer'
import GetAppCTA from 'components/GetAppCTA'

const HomeLayout = ({ children }) => (
  <>
    <Header />
    {children}
    <GetAppCTA />
    <Footer />
  </>
)

HomeLayout.propTypes = {
  children: PropTypes.node,
}

export default HomeLayout
