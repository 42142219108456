import React from 'react'
import PropTypes from 'prop-types'

const DefaultFill = '#FFFFFF'
const DefaultWidth = 111
const DefaultHeight = 20

const FastAFLogo = ({ fill, width, height }) => {
  let useWidth = DefaultWidth
  let useHeight = DefaultHeight
  if (!width && height) {
    useWidth = 111 * (height / 24)
    useHeight = height
  } else if (width && !height) {
    useWidth = width
    useHeight = 24 * (width / 111)
  } else if (width && height) {
    useWidth = width
    useHeight = height
  }

  return (
    <svg
      width={useWidth}
      height={useHeight}
      viewBox={`0 0 ${111} ${24}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.5634 24C50.3178 24 53.8177 21.3474 53.8177 16.8474C53.8177 13.2316 51.5159 10.9579 46.7863 9.91579L44.863 9.47369C42.7031 8.98421 41.1896 8.43158 41.1896 7.01053C41.1896 5.66842 42.2459 4.64211 44.4688 4.64211C46.7548 4.64211 47.9057 5.82632 48.0476 7.61053L53.4235 7.61053C53.3762 3.07895 49.9709 0 44.4846 0C39.0613 0 35.3565 3 35.3565 7.35789C35.3565 11.0368 38.0208 12.8684 41.8833 13.7211L44.2797 14.2737C46.8179 14.8421 48.0949 15.4105 48.0949 16.8632C48.0949 18.2842 46.8179 19.3421 44.5319 19.3421C41.836 19.3421 40.228 18.0158 40.1807 15.6316L34.8047 15.6316C34.8047 21.2842 38.6199 24 44.5634 24ZM5.83607 15.1033L5.83607 23.6883H0L0 0.415576L16.2078 0.415576V5.38L5.83607 5.38L5.83607 10.3444L14.8915 10.3444L14.8915 15.1033L5.83607 15.1033ZM18.775 23.6883L20.2739 18.9768L28.3839 18.9768L29.8512 23.6883H36.052L28.1945 0.415576L20.6526 0.415576L12.7793 23.6883H18.775ZM26.98 14.4551L21.7102 14.4551L24.3609 6.1231H24.3924L26.98 14.4551ZM66.5534 5.38V23.6883L60.7196 23.6883L60.7196 5.38L53.5066 5.38V0.415576L73.7664 0.415576V5.38L66.5534 5.38ZM75.734 18.9768L73.4461 23.6883H67.3242L79.0474 0.415576L86.5893 0.415576L90.3318 22.1483L93.922 0.415576L110.025 0.415576L109.206 5.38L98.9011 5.38L98.066 10.3444L107.079 10.3444L106.307 15.1033L97.3097 15.1033L95.8758 23.6883L90.5969 23.6883L90.0773 23.6883L84.5224 23.6883L83.8439 18.9768L75.734 18.9768ZM77.9121 14.4551H83.182L81.9829 6.1231H81.9513L77.9121 14.4551Z"
        fill={fill}
      />
    </svg>
  )
}

FastAFLogo.defaultProps = {
  fill: DefaultFill,
}

FastAFLogo.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default FastAFLogo
