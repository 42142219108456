import React from 'react'
import PropTypes from 'prop-types'

import MuiButton from '@material-ui/core/Button'

import { BUTTON_VARIANT, SIZE, COLOR } from 'constants/enums'

const Button = ({
  variant,
  color,
  size,
  href,
  disabled,
  onClick,
  children,
  className,
  fullWidth,
  type,
  target,
  startIcon
}) => (
  <MuiButton
    variant={variant}
    color={color}
    size={size}
    href={href}
    disabled={disabled}
    onClick={onClick}
    disableElevation
    className={className}
    fullWidth={fullWidth}
    type={type}
    target={target}
    startIcon={startIcon}
  >
    {children}
  </MuiButton>
)

Button.defaultProps = {
  variant: BUTTON_VARIANT.contained,
  color: COLOR.primary,
  size: SIZE.medium,
  disabled: false
}

Button.propTypes = {
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT)),
  color: PropTypes.oneOf(Object.values(COLOR)),
  size: PropTypes.oneOf(Object.values(SIZE)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  startIcon: PropTypes.node,
}

export default Button
