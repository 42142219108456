import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import MaximizeIcon from '@material-ui/icons/Maximize'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core'

import styles from './HamburgerMenuButtonStyles'

const HamburgerMenuButton = ({ classes, menuShown, onClick, color }) => {
  return (
    <IconButton
        aria-label="Menu"
        classes={{ root: classes.root, label: classes.label }}
        onClick={onClick}
    >
      {menuShown && (
        <CloseIcon style={{ color }} />
      )}
      {!menuShown && (
        <>
          <MaximizeIcon viewBox="0 0 24 16" className={classes.icon1} style={{ color }} />
          <MaximizeIcon viewBox="0 0 24 4" style={{ color }} />
        </>
      )}
    </IconButton>
  )
}

HamburgerMenuButton.propTypes = {
  classes: PropTypes.object.isRequired,
  menuShown: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string.isRequired
}

HamburgerMenuButton.defaultProps = {
  menuShown: false,
  onClick: () => {}
}

export default withStyles(styles)(HamburgerMenuButton)
