import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { useMediaQuery } from 'hooks'
import { APP_STORE_LINK } from 'lib/config'
import ExternalLink from 'components/ExternalLink'
import FastAFQR from 'assets/Images/fastaf-QR.png'

import styles from './GetAppLinkStyles'

const GetAppLink = ({ className, classes, color }) => {
  const { isDesktop } = useMediaQuery()
  const containerClasses = classnames(
    className,
    classes.root,
    classes[color + 'Color']
  )

  if (isDesktop) {
    // Desktop - "Scan to download the app"
    return (
      <Box className={containerClasses}>
        <ExternalLink
          onClick={(e) => {
            e.preventDefault()
            window.open(APP_STORE_LINK, '_blank')
          }}
          href={APP_STORE_LINK}
          target="_blank"
          className={classes.link}
        >
          <img className={classes.qrImg} src={FastAFQR} alt="QR Code" />
          <Typography variant="h6" className={classes.linkText}>
            Scan to get
            <br />
            the App
          </Typography>
        </ExternalLink>
      </Box>
    )
  }

  // Mobile version removed, as we now have the bottom sticky bar
  return null
}

GetAppLink.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  color: PropTypes.oneOf(['primary', 'secondary']),
}

GetAppLink.defaultProps = {
  color: 'primary',
}

export default withStyles(styles)(GetAppLink)
