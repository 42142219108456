import { useTheme } from '@material-ui/core/styles'
import muiUseMediaQuery from '@material-ui/core/useMediaQuery'

const useMediaQuery = () => {
  const theme = useTheme()

  const isDesktop = muiUseMediaQuery(theme.breakpoints.up('desktop'))
  const isMobile = muiUseMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values['tablet'])
  )
  const isMobileTablet = !isDesktop

  return {
    isMobile,
    isMobileTablet,
    isDesktop,
  }
}

export default useMediaQuery
