const jssStyles = (theme) => ({
  root: {
    paddingBottom: '6.0rem',
    paddingTop: '6.0rem',
    [theme.breakpoints.up('desktop')]: {
      paddingBottom: '16.0rem',
      paddingTop: '16.0rem'
    },
    '& + &': {
      paddingTop: 0
    }
  },
  noCollapse: {
    paddingBottom: '6.0rem',
    paddingTop: '6.0rem',
    [theme.breakpoints.up('desktop')]: {
      paddingBottom: '16.0rem',
      paddingTop: '16.0rem'
    }
  }
})

export default jssStyles
