const jssStyles = (theme) => ({
  root: {
    margin: '0 auto',
  },
  primaryColor: {
    '& $qrImg': {
      filter: 'invert()',
    },
    '& $link': {
      color: theme.palette.text.primary,
    },
    '& $linkText': {
      color: theme.palette.text.primary,
    },
  },
  secondaryColor: {
    '& $link': {
      color: theme.palette.common.white,
    },
    '& $linkText': {
      color: theme.palette.common.white,
    },
  },
  link: {},
  qrImg: {
    height: '8rem',
    width: '8rem',
  },
  linkText: {
    textAlign: 'center',
  },
})

export default jssStyles
