const jssStyles = (theme) => ({
  // Mobile styles (fixed bar at the bottom of the screen)
  mobileRoot: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 2,
  },
  appStoreLink: {
    background: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '8.0rem',
  },
  appleIcon: {
    height: '2.4rem',
    marginRight: '1.0rem',
  },
  linkText: {
    color: theme.palette.common.white,
  },

  // Desktop styles (section above footer)
  section: {
    background: theme.palette.common.white,
  },
  secondaryBg: {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  ternaryBg: {
    background: theme.palette.grays.light,
  },
  container: {
    textAlign: 'center',
  },
  ctaHeader: {
    marginBottom: '3.2rem',
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '4.0rem',
    },
  },
  downloadLink: {
    marginBottom: '1.6rem',
    [theme.breakpoints.up('desktop')]: {
      marginBottom: '3.2rem',
    },
  },
  formLink: {
    lineHeight: '1.9rem',
  },
})

export default jssStyles
