import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './DimmerStyles'

const Dimmer = ({ classes, isLoading, children, ...props }) => {
  return (
    <Backdrop className={classes.backdrop} open={ isLoading }>
      { children }
    </Backdrop>
  )
}

export default withStyles(styles)(Dimmer)
