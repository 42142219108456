import axios from 'axios'
import { SERVER_URL } from 'lib/config'

const headers = { 'Content-Type': 'application/json' }

// on page reload, get the token from localStorage
const token = localStorage.getItem('authToken')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const api = axios.create({
  baseURL: SERVER_URL,
  headers,
  timeout: 200000
})

api.interceptors.response.use(
  resp => Promise.resolve(resp.data),
  error => Promise.reject(error.response || error)
)

export default api
