import React from 'react'
import PropTypes from 'prop-types'
import MuiLink from '@material-ui/core/Link'

const ExternalLink = ({ children, to, href, ...otherProps }) => (
  <MuiLink
      href={to || href}
      target="_blank"
      rel="noopener"
      {...otherProps}
  >{children}</MuiLink>
)

ExternalLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  href: PropTypes.string
}

export default ExternalLink
