import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dimmer from 'components/Dimmer'

const Spinner = ({ isLoading, ...props }) => (
  <Dimmer isLoading={isLoading}>
    <CircularProgress
      variant="indeterminate"
      size={70}
      thickness={5}
      {...props}
    />
  </Dimmer>
)

export default Spinner
