import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/core/styles'
// import { ChakraProvider } from '@chakra-ui/react'

import './index.css'
import 'overlayscrollbars/css/OverlayScrollbars.css'

import App from './App'
import { AppProvider } from 'context'
import { theme } from './theme'
import * as serviceWorker from './serviceWorker'

const rootElement = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <ThemeProvider theme={theme}>
        {/* <ChakraProvider> */}
          <App />
        {/* </ChakraProvider> */}
      </ThemeProvider>
    </AppProvider>
  </React.StrictMode>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
