const jssStyles = {
  root: {
    padding: 0
  },
  label: {
    position: 'relative',
    overflow: 'hidden'
  },
  icon1: {
    position: 'absolute',
    left: 0,
    top: 0
  }
}

export default jssStyles
