const jssStyles = (theme) => ({
  footer: {
    background: theme.palette.common.white,
    borderTop: ['0.2rem', 'solid', theme.palette.grays.light].join(' '),
    padding: '4.0rem 3.2rem 10.5rem',
    width: '100%',
    [theme.breakpoints.up('desktop')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  secondaryBg: {
    background: theme.palette.common.black,
    borderTop: ['0.1rem', 'solid', theme.palette.common.white].join(' '),
    color: theme.palette.common.white,

    '& $link': {
      color: theme.palette.common.white,
      [theme.breakpoints.up('desktop')]: {
        '&:hover': {
          color: theme.palette.common.white,
        },
      },
    },

    '& $socialLink': {
      color: theme.palette.common.white,
      [theme.breakpoints.up('desktop')]: {
        '&:hover': {
          color: theme.palette.text.white,
        },
      },
    },
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    margin: '1.6rem 0',
    fontWeight: 600,
    [theme.breakpoints.up('desktop')]: {
      margin: '2.4rem 5.6rem 2.4rem 0',
      '&:hover': {
        color: theme.palette.text.primary,
      },
    },
    '&:first-child': {
      marginTop: 0,
      [theme.breakpoints.up('desktop')]: {
        marginTop: '2.4rem',
      },
    },
    '&:last-child': {
      marginBottom: 0,
      [theme.breakpoints.up('desktop')]: {
        marginBottom: '2.4rem',
      },
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
  },
  disabledLink: {
    opacity: 0.6,
    color: theme.palette.grays.med,

    '&:hover': {
      cursor: 'default',
      textDecoration: 'none',
      color: theme.palette.grays.med,
    },
  },
  socialLink: {
    color: theme.palette.text.primary,
    margin: '4.0rem 3.2rem 4.0rem 0',
    display: 'flex',
    [theme.breakpoints.up('desktop')]: {
      margin: '0 3.2rem 0 0',
      '&:hover': {
        color: theme.palette.text.secondary,
      },
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  socialIcon: {
    fontSize: '2.1rem',
  },
  innerWrap: {
    margin: '0 auto',
    maxWidth: theme.layout.desktopWrapWidth,
    width: '100%',
    [theme.breakpoints.up('desktop')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '12.0rem',
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('desktop')]: {
      flexDirection: 'row',
      flexGrow: 1,
    },
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  copyrightContainer: {
    textAlign: 'center',
    [theme.breakpoints.up('desktop')]: {
      marginRight: '12.8rem',
      order: -1,
      display: 'flex',
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
})

export default jssStyles
