import React from 'react'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Footer from 'components/Footer'
import GetAppCTA from 'components/GetAppCTA'
import ContactSection from 'components/ContactSection'

const DefaultLayout = ({
  children,
  hasContactSection,
  hasAppCTA,
  hasFooter,
  headerProps,
  appCTAProps,
}) => (
  <>
    <Header {...headerProps} />
    {children}
    {hasAppCTA && (<GetAppCTA {...appCTAProps} />)}
    {hasContactSection && (<ContactSection />)}
    {hasFooter && (<Footer />)}
  </>
)

DefaultLayout.propTypes = {
  children: PropTypes.node,
  hasContactSection: PropTypes.bool,
  hasAppCTA: PropTypes.bool,
  hasFooter: PropTypes.bool,
  headerProps: PropTypes.object,
  appCTAProps: PropTypes.object,
}

DefaultLayout.defaultProps = {
  hasContactSection: true,
  hasAppCTA: true,
  hasFooter: true,
  headerProps: {},
  appCTAProps: {}
}

export default DefaultLayout
